import React, { useContext, useEffect, useRef, useMemo } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"

import SearchForm from "./SearchForm"
import SearchResults from "./SearchResults"
import SearchModalContext from "../../contexts/SearchModalContext"

const INDEX_NAME = process.env.GATSBY_ALGOLIA_INDEX_NAME

const SearchModal = () => {
  const { isOpen, setIsOpen, query, setQuery } = useContext(SearchModalContext)
  const modalContainerRef = useRef()

  const escPressHandler = e => {
    if (e.keyCode === 27) setIsOpen(false)
  }

  const windowClickHandler = e => {
    if (e.target === modalContainerRef.current) setIsOpen(false)
  }

  const addCloseListeners = () => {
    if (typeof window === undefined) return
    window.addEventListener("keydown", escPressHandler)
    window.addEventListener("click", windowClickHandler)
  }

  const removeCloseListeners = () => {
    if (typeof window === undefined) return
    window.removeEventListener("keydown", escPressHandler)
    window.removeEventListener("click", windowClickHandler)
  }

  useEffect(() => {
    const bodyClassList = document.body.classList

    if (isOpen) {
      bodyClassList.add("overflow-hidden")
      addCloseListeners()
    } else {
      bodyClassList.remove("overflow-hidden")
      removeCloseListeners()
    }

    return () => {
      bodyClassList.remove("overflow-hidden")
      removeCloseListeners()
    }
  }, [isOpen])

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  return (
    isOpen && (
      <div
        className="fixed inset-0 z-50 bg-night-black bg-opacity-75 p-8 lg:p-12"
        ref={modalContainerRef}
      >
        <div className="bg-white mx-auto flex max-h-full max-w-screen-sm lg:max-w-screen-md">
          <InstantSearch
            searchClient={searchClient}
            onSearchStateChange={({ query }) => setQuery(query)}
            indexName={INDEX_NAME}
          >
            <div className="flex max-h-full w-full flex-col">
              <SearchForm />
              {query && (
                <SearchResults
                  indices={[{ name: INDEX_NAME, title: INDEX_NAME }]}
                />
              )}
            </div>
          </InstantSearch>
        </div>
      </div>
    )
  )
}

export default SearchModal
