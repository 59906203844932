import React from "react"
import { PrismicLink } from "@prismicio/react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults?.nbHits
  return (
    <div className="text-md bg-sand-yellow px-6 py-2">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  )
})

const PageHit = ({ hit }) => {
  const { type } = hit

  let snippetAttribute = ""
  switch (type) {
    case "pages":
      snippetAttribute = "data.content_section.0.primary.content.text"
      break
    case "contact":
      snippetAttribute = "data.form_section_content.text"
      break
    default:
      snippetAttribute = ""
      break
  }

  const hasSnippet = !!snippetAttribute
    .split(".")
    .reduce((prev, current) => (prev && prev[current]) || null, hit)

  return (
    <PrismicLink
      className="border-grey-lighter hover:text-black block border-t px-6 py-4 hover:bg-night-black hover:bg-opacity-10"
      field={hit}
    >
      <div className="space-y-1">
        <h4 className="text-lg font-semibold uppercase tracking-wide">
          <Highlight attribute="data.header_title" hit={hit} tagName="mark" />
        </h4>
        {hasSnippet && (
          <p className="text-base">
            <Snippet attribute={snippetAttribute} hit={hit} tagName="mark" />
          </p>
        )}
      </div>
    </PrismicLink>
  )
}

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <div className="flex max-h-full flex-col">
      <HitCount />
      <div className="overflow-y-auto">
        <Hits hitComponent={PageHit} />
      </div>
    </div>
  </Index>
)

const SearchResults = ({ indices }) => (
  <div className="overflow-hidden bg-cloud-white">
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResults
