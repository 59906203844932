import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"

import { IconX } from "../SVG/Icons"

const FlyoutMenu = ({ isOpen, setIsOpen, primaryItems, secondaryItems }) => {
  useEffect(() => {
    const documentClassList = document.documentElement.classList

    if (isOpen) {
      documentClassList.add("overflow-hidden")
    } else {
      documentClassList.remove("overflow-hidden")
    }

    return () => {
      documentClassList.remove("overflow-hidden")
    }
  }, [isOpen])

  if (!primaryItems?.length) return null

  const handleCloseClick = () => setIsOpen(false)
  const handleOverlayClick = () => setIsOpen(false)

  return (
    <>
      <div
        className={`fixed inset-0 z-40 bg-land-brown bg-opacity-50 transition-all duration-300 ${
          !isOpen ? "invisible opacity-0" : "visible opacity-100"
        }`}
        onClick={handleOverlayClick}
      ></div>
      <nav
        className={`fixed inset-y-0 right-0 z-50 w-[520px] max-w-full transform bg-land-brown pt-24 pb-36 text-cloud-white duration-300 ${
          !isOpen ? "translate-x-full" : "translate-x-0"
        }`}
      >
        <button
          className="absolute top-0 right-0 mt-9 mr-9"
          onClick={handleCloseClick}
        >
          <IconX className="h-5 w-5 fill-current" />
        </button>
        <div className="flex h-full flex-1 flex-col justify-between overflow-y-auto px-gutter xs:px-14">
          <ul className="mb-8 space-y-5 ">
            {primaryItems.map((item, index) => {
              const {
                fields: { title, link },
                sub_items,
              } = item

              return (
                <li key={`${index}-${title}`}>
                  <PrismicLink
                    className="text-3xl font-bold uppercase tracking-wide"
                    field={link}
                  >
                    {title}
                  </PrismicLink>

                  {!!sub_items?.length && (
                    <ul className="mt-3 space-y-1">
                      {sub_items.map(({ title, link }, index) => {
                        if (!title && !link) return null

                        return (
                          <li key={index}>
                            <PrismicLink
                              className="font-bold uppercase tracking-wide"
                              field={link}
                            >
                              {title}
                            </PrismicLink>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>

          {secondaryItems?.length && (
            <ul className="space-y-1">
              {secondaryItems.map(({ title, link }) => {
                if (!title || !link?.url) return null

                return (
                  <li key={title}>
                    <PrismicLink
                      className="text-base font-bold uppercase tracking-wide"
                      field={link}
                    >
                      {title}
                    </PrismicLink>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <StaticImage
          className="absolute inset-x-0 bottom-0"
          src="../../images/flyout-menu-pattern.png"
          alt=""
          placeholder="none"
        />
      </nav>
    </>
  )
}

export default FlyoutMenu
