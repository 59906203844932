import React, { useState, useRef, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import Cookies from "js-cookie"

import HeaderNav from "./HeaderNav"
import FlyoutMenu from "../FlyoutMenu/FlyoutMenu"
import Logo from "../SVG/Logo"
import { IconX, IconMagnifier } from "../SVG/Icons"
import SearchModalContext from "../../contexts/SearchModalContext"

const BAR_HIDDEN_COOKIE_KEY = "barHidden"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      global: prismicGlobal {
        fields: data {
          bar_content: header_bar_content
          primary_navigation {
            ... on PrismicGlobalDataPrimaryNavigationNavigationItem {
              id
              fields: primary {
                title
                link {
                  ...linkFragment
                }
              }
              sub_items: items {
                title
                link {
                  ...linkFragment
                }
              }
            }
          }
          secondary_navigation {
            title
            link {
              ...linkFragment
            }
          }
        }
      }
    }
  `)

  const { bar_content, primary_navigation, secondary_navigation } =
    data.global.fields

  const [isBarHidden, setIsBarHidden] = useState(false)
  const [hasScrolledPastHeader, setHasScrolledPastHeader] = useState(false)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [isFlyoutMenuOpen, setIsFlyoutMenuOpen] = useState(false)
  const { setIsOpen: setIsSearchModalOpen } = useContext(SearchModalContext)
  const headerRef = useRef()
  const prevScrollYPos = useRef(0)

  useEffect(() => {
    const isBarHiddenDefault =
      !bar_content || Cookies.get(BAR_HIDDEN_COOKIE_KEY) === bar_content

    setIsBarHidden(isBarHiddenDefault)

    window.addEventListener("scroll", handlePageScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handlePageScroll)
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--site-header-height",
      `${headerRef.current.getBoundingClientRect().height}px`
    )

    if (isBarHidden) {
      Cookies.set(BAR_HIDDEN_COOKIE_KEY, bar_content)
    }
  }, [isBarHidden])

  useEffect(() => {
    headerRef.current.style.setProperty(
      "top",
      hasScrolledPastHeader && isScrollingUp
        ? 0
        : "calc(-1 * var(--site-header-height))"
    )
  }, [hasScrolledPastHeader, isScrollingUp])

  const handleBarCloseClick = () => setIsBarHidden(true)

  const handleSearchClick = () => {
    setIsSearchModalOpen(true)
  }

  const handlePageScroll = () => {
    const scrollYPos = window.scrollY

    setHasScrolledPastHeader(prevState => {
      return prevState && scrollYPos !== 0
        ? true
        : scrollYPos > headerRef.current.getBoundingClientRect().height
    })
    setIsScrollingUp(scrollYPos > 0 && scrollYPos < prevScrollYPos.current)

    prevScrollYPos.current = scrollYPos
  }

  const handleMenuClick = () => setIsFlyoutMenuOpen(prevState => !prevState)

  return (
    <header
      className={`sticky z-50 duration-300 ${
        isScrollingUp ? "bg-cloud-white" : ""
      }`}
      ref={headerRef}
    >
      {!isBarHidden && (
        <div className="bg-land-brown px-gutter py-4">
          <div className="flex text-cloud-white lg:pl-6">
            <p className="mr-3 flex-1 font-semibold tracking-wide lg:text-center">
              {bar_content}
            </p>

            <button className="ml-auto h-[1.5em]" onClick={handleBarCloseClick}>
              <IconX className="h-3 w-3 fill-current" />
            </button>
          </div>
        </div>
      )}
      <div className="px-gutter py-10">
        <div className="flex items-center gap-x-8 2xl:gap-x-10">
          <PrismicLink href="/">
            <Logo className="w-20 fill-night-black 2xl:w-32" />
          </PrismicLink>

          <HeaderNav items={primary_navigation} />

          <div className="ml-auto flex items-center">
            <div className="mr-8 hidden space-x-2 whitespace-nowrap rounded-xl bg-night-black py-2 px-4 uppercase leading-none text-cloud-white lg:block">
              <div className="inline-block">
                <span
                  className="space-x-1"
                  data-yourir="context symbol=kmd.asx"
                >
                  <span className="font-bold" data-yourir="price"></span>
                  <span className="font-bold">ASX</span>
                  <span
                    className="text-sm"
                    data-yourir="change minDecimals=3 maxDecimals=3"
                  ></span>
                </span>
                {/* <svg
                  data-yourir="changeSignCSS"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 13 10"
                  className="stock-symbol mr-3 inline-block h-4 w-4 fill-current"
                >
                  <path d="m6.5.333 5.63 9.63H.87L6.5.333Z" />
                </svg> */}
              </div>
              <div className="inline-block">
                <span
                  className="space-x-1"
                  data-yourir="context symbol=kmd.nzx"
                >
                  <span className="font-bold" data-yourir="price"></span>
                  <span className="font-bold">NZX</span>
                  <span
                    className="text-sm"
                    data-yourir="change minDecimals=3 maxDecimals=3"
                  ></span>
                </span>
                {/* <svg
                  data-yourir="changeSignCSS"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 13 10"
                  className="stock-symbol inline-block h-4 w-4 fill-current"
                >
                  <path d="m6.5.333 5.63 9.63H.87L6.5.333Z" />
                </svg> */}
              </div>
            </div>

            <div className="flex items-center gap-x-5">
              <button className="h-6 w-6" onClick={handleSearchClick}>
                <IconMagnifier className="fill-current" />
              </button>

              <div className="h-6 border-r lg:hidden"></div>

              <button
                className="text-4xl font-bold leading-none lg:hidden"
                onClick={handleMenuClick}
              >
                Menu
              </button>

              <FlyoutMenu
                isOpen={isFlyoutMenuOpen}
                setIsOpen={setIsFlyoutMenuOpen}
                primaryItems={primary_navigation}
                secondaryItems={secondary_navigation}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
