import React, { useCallback } from "react"
import { connectSearchBox } from "react-instantsearch-dom"

const SearchForm = connectSearchBox(({ refine, currentRefinement }) => {
  const inputRef = useCallback(inputNode => {
    inputNode?.focus()
  }, [])

  const handleInputChange = e => {
    refine(e.target.value)
  }

  return (
    <form>
      <input
        className="w-full rounded-none bg-night-black p-6 text-lg font-semibold text-cloud-white placeholder:text-cloud-white focus:outline-none lg:p-8 lg:text-2xl"
        type="text"
        name="search"
        value={currentRefinement}
        onChange={handleInputChange}
        placeholder="What are you searching for?"
        ref={inputRef}
      ></input>
    </form>
  )
})

export default SearchForm
