import React, { useEffect, useRef } from "react"
import { load, removeNode, reprocessNode } from "yourir-client"

const uniqid = ra => {
  const sec = Date.now() * 1000 + Math.random() * 1000
  return sec.toString(16).replace(/\./g, "").padEnd(14, "0")
}

const YourIr = props => {
  const yourIrWrapper = useRef(null)

  useEffect(() => {
    if (!window) {
      return
    }

    if (!window.yourir) {
      load("934cba5b9a243bec")
    }

    const id = uniqid()
    reprocessNode(id, yourIrWrapper.current)

    return () => {
      removeNode(yourIrWrapper.current)
    }
  })

  return <div ref={yourIrWrapper}>{props.children}</div>
}

export default YourIr
