import React, { useState, useEffect } from "react"
import { PrismicLink } from "@prismicio/react"

const HeaderNav = ({ items }) => {
  const [openItemIndex, setOpenItemIndex] = useState(null)

  useEffect(() => {
    if (openItemIndex) {
      window.addEventListener("scroll", handlePageScroll, {
        passive: true,
        once: true,
      })
    }
  }, [openItemIndex])

  if (!items) return null

  const handleNavMouseLeave = () => setOpenItemIndex(null)

  const handleItemMouseEnter = (index, hasSubItems) => {
    setOpenItemIndex(!isNaN(index) && hasSubItems ? index : null)
  }

  const handlePageScroll = () => setOpenItemIndex(null)

  return (
    <nav className="hidden lg:block" onMouseLeave={handleNavMouseLeave}>
      <ul className="flex gap-x-8 text-xs font-bold uppercase leading-tight tracking-wide xl:text-base 2xl:gap-x-10 2xl:text-lg">
        {items.map((item, index) => {
          const {
            fields: { title, link },
            sub_items,
          } = item

          if (!title || !(link && link.url)) return null

          const isItemOpen = index === openItemIndex
          const hasSubItems = !!sub_items.length

          return (
            <li
              className="relative max-w-[11em]"
              key={index}
              onMouseEnter={() => handleItemMouseEnter(index, hasSubItems)}
            >
              <PrismicLink
                className="hover:text-sky-blue focus:text-sky-blue"
                activeClassName="text-sky-blue"
                field={link}
              >
                {title}
              </PrismicLink>
              {hasSubItems && (
                <ul
                  className={`absolute top-full space-y-3 bg-night-black p-6 text-cloud-white transition-all duration-300 ${
                    !isItemOpen ? "invisible opacity-0" : "visible opacity-100"
                  }`}
                >
                  {sub_items.map(({ title, link }, subIndex) => {
                    if (!title && !link) return null

                    return (
                      <li key={subIndex}>
                        <PrismicLink
                          className="hover:text-sky-blue focus:text-sky-blue"
                          activeClassName="text-sky-blue"
                          field={link}
                        >
                          {title}
                        </PrismicLink>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default HeaderNav
