import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicLink } from "@prismicio/react"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      global: prismicGlobal {
        fields: data {
          primary_navigation {
            ... on PrismicGlobalDataPrimaryNavigationNavigationItem {
              id
              fields: primary {
                title
                link {
                  ...linkFragment
                }
              }
            }
          }
          brands: footer_brands {
            brand {
              document {
                ...brandsFragment
              }
            }
          }
          subscribe_link: footer_subscribe_link {
            ...linkFragment
          }
          secondary_navigation {
            title
            link {
              ...linkFragment
            }
          }
        }
      }
    }
  `)

  const today = new Date()

  const { primary_navigation, brands, subscribe_link, secondary_navigation } =
    data.global.fields

  return (
    <footer className="bg-land-brown text-cloud-white">
      <div className="section section--reduced-b">
        <div className="mb-24 lg:flex lg:justify-between">
          {primary_navigation?.length && (
            <ul className="space-y-2 sm:space-y-3">
              {primary_navigation.map(item => {
                const {
                  id,
                  fields: { title, link },
                } = item

                if (!title || !link?.url) return null

                return (
                  <li key={id}>
                    <PrismicLink
                      className="inline-block text-2xl font-bold uppercase tracking-wide hover:text-night-black focus:text-night-black sm:max-w-md sm:text-4xl"
                      field={link}
                    >
                      {title}
                    </PrismicLink>
                  </li>
                )
              })}
            </ul>
          )}
          {brands?.length && (
            <div className="mt-10 lg:mt-0">
              <div className="sm:flex sm:space-x-5 lg:space-x-10">
                {brands.map(item => {
                  const { id, title, logo_secondary, website_link } =
                    item.brand.document.data

                  return (
                    <PrismicLink
                      className="flex h-14 w-36 items-end"
                      field={website_link}
                      title={title}
                      key={`${id}-${title}`}
                    >
                      <GatsbyImage
                        objectFit="contain"
                        image={getImage(logo_secondary.gatsbyImageData)}
                        alt={logo_secondary.alt || title}
                      />
                    </PrismicLink>
                  )
                })}
              </div>
            </div>
          )}
        </div>

        <div className="grid items-end justify-between sm:grid-cols-3 sm:gap-x-10">
          {subscribe_link && (
            <div className="mb-10 sm:col-span-2 sm:mb-5 sm:text-right">
              <PrismicLink
                className="button button--small button--white"
                field={subscribe_link}
              >
                Subscribe to investor email alerts
              </PrismicLink>
            </div>
          )}
          <div className="mb-5 sm:col-start-1 sm:row-start-1">
            <a
              className="inline-block hover:text-night-black focus:text-night-black"
              href="https://www.linkedin.com/company/79532117/admin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="h-9 w-9 fill-current"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>LinkedIn</title>
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
              </svg>
            </a>
          </div>
          {secondary_navigation?.length && (
            <div className="sm:col-span-2 sm:text-right">
              <ul className="inline-flex space-x-6">
                {secondary_navigation.map(({ title, link }, index) => {
                  if (!title || !link?.url) return null

                  return (
                    <li key={index}>
                      <PrismicLink
                        className="font-semibold uppercase tracking-wide hover:text-night-black focus:text-night-black"
                        field={link}
                      >
                        {title}
                      </PrismicLink>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
          <small className="inline-block pt-5 text-base font-semibold uppercase tracking-wide sm:col-start-1 sm:row-start-2 sm:pt-0">
            &copy; {today.getFullYear()} KMD Brands
          </small>
        </div>
      </div>
    </footer>
  )
}

export default Footer
